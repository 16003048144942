import * as actionTypes from '../actions/types'
import { updateObject } from '../shared/utility'

const initialState = {
  data: {},
  loading: false,
  error: {},
}

const fetchOperationalsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchOperationalsSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    loading: false
  })
}

const fetchOperationalsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const updateOperationalStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const updateOperationalSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  })
}

const updateOperationalFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const clearErrorOperational = (state, action) => {
  return updateObject(state, {
    error: {},
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OPERATIONALS_START:
      return fetchOperationalsStart(state, action)
    case actionTypes.FETCH_OPERATIONALS_SUCCESS:
      return fetchOperationalsSuccess(state, action)
    case actionTypes.FETCH_OPERATIONALS_FAIL:
      return fetchOperationalsFail(state, action)
    case actionTypes.UPDATE_OPERATIONAL_START:
      return updateOperationalStart(state, action)
    case actionTypes.UPDATE_OPERATIONAL_SUCCESS:
      return updateOperationalSuccess(state, action)
    case actionTypes.UPDATE_OPERATIONAL_FAIL:
      return updateOperationalFail(state, action)
    case actionTypes.CLEAR_ERROR_OPERATIONAL:
      return clearErrorOperational(state, action)
    default: return state
  }
}

export default reducer