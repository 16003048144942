import React, { useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Grid, Typography
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import * as actions  from '../../../actions'

// component
import ProductPrice from './ProductPrice'
import ProductPriceBuyBack from './ProductPriceBuyBack'
import Loading from "../../../components/UI/Loading";
import {isEmpty} from "../../../shared/utility";
import FormEdit from "./FormEdit/FormEdit";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const EditProduct = (props) => {
    const { onGetProduct, product, loading } = props
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => {
        onGetProduct(id)
    }, [onGetProduct, id])
    // console.log(product)
    let formEdit = '';
    if((!isEmpty(product))){
        formEdit = (
          <Fragment>
              <FormEdit product={product} classes={classes} id={id} history={history}/>
          </Fragment>
        )
    }

    return loading ? <Loading/>
    : 
    <Fragment>
        <div className={classes.root}>
            <div className={classes.row}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Edit Produk</Typography>
                        {/* <Typography>{detailProduct.weight}</Typography> */}
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                {formEdit}
            </div>
            <div className={classes.row}>
                <Grid container spacing={2}>
                    <Grid 
                        item 
                        lg={6} 
                        md={6}
                        sm={12}
                        xs={12}
                    >
                        <ProductPrice />
                    </Grid>
                    <Grid 
                        item
                        lg={6} 
                        md={6}
                        sm={12}
                        xs={12}
                    >
                        <ProductPriceBuyBack />
                    </Grid>
                </Grid>
            </div>
        </div>
    </Fragment>
}

const mapStateToProps = state => {
    return {
        product: state.product.productV2,
        loading: state.product.loadingV2
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetProduct: (id) => dispatch(actions.getProductV2(id)),
        onUpdateProduct: (id, formData, image, history) => dispatch(actions.updateProduct(id, formData, image, history))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)