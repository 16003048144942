import { combineReducers } from 'redux'
import alert from './alerts'
import login from './login'
import customer from './customer'
import dashboard from './dashboard'
import branch from './branch'
import village from './village'
import purchaseOrder from './purchaseOrder'
import product from './product'
import productPrice from './productPrice'
import productPriceBuyback from './productPriceBuyback'
import user from './user'
import resetStockCabang from './resetStockCabang'
import round from './round'
import quotaProduct from './quotaProduct'
import branchPurchaseQuota from './branchPurchaseQuota'
import notification from './notification'
import operational from './operational'

const reducers = combineReducers({
    alert,
    login,
    customer,
    dashboard,
    branch,
    village,
    purchaseOrder,
    product,
    productPrice,
    productPriceBuyback,
    user,
    resetStockCabang,
    round,
    quotaProduct,
    branchPurchaseQuota,
    notification,
    operational
});

export default reducers;