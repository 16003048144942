import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Modal } from '../../components/UI';
import ListRound from './List';
import EditRound from './Edit/EditRound';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tagMenu: {
    fontWeight: 'bold',
  },
  addButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'none',
    fontWeight: 'bold',
    borderRadius: '60px',
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
  paperTag: {
    marginBottom: theme.spacing(3),
  },
  boxMarginRow: {
    margin: 'auto auto 15px',
  },
  boxMarginColumn: {
    margin: 'auto auto auto 10px',
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: 'none',
    fontWeight: 'bold',
    margin: 'auto auto auto 10px',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  cancelButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    textTransform: 'none',
    fontWeight: 'bold',
    margin: 'auto auto auto 10px',
    '&:hover': {
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
    },
  },
}));

const OperationalPos = () => {
  const classes = useStyles();

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const edit = (data) => {
    setModalState({
      open: true,
      title: 'Form Edit Jam Operational',
      maxWidth: 'sm',
    });
    setForm(
      <EditRound
        closedModalDialog={() => closedModalDialog()}
        classes={classes}
        round={data}
      />
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />

        <div className={classes.row}>
          <Grid container spacing={2} justify={'space-between'}>
            <Grid item>
              <Typography variant={'h4'} className={classes.tagMenu}>
                Pengaturan Jam Operational POS-Agen & Member
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={'outlined'} className={classes.paperRoot}>
                <div>
                  <ListRound edit={(data) => edit(data)} />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

export default OperationalPos;
