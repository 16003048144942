import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CapitalizedText from '../../../components/layout/CapitalizedText'

import { connect } from 'react-redux';
import * as actions from '../../../actions'
import {
	Tooltip,
	IconButton, TableFooter,
} from '@material-ui/core';
import { Loading, TablePaginationsArrayActions } from "../../../components/UI";
import { isEmpty } from "../../../shared/utility";

const columns = [
	{ id: 'no', label: 'No', minWidth: 100 },
	{ id: 'nama', label: 'Nama Item', minWidth: 150 },
	{ id: 'berat', label: 'Berat / Banyak', minWidth: 200 },
	{ id: 'satuan', label: 'Satuan', minWidth: 100 },
	{ id: 'is_active', label: 'Status', minWidth: 100 },
	{ id: 'is_display_egs', label: 'Is Display EGS', minWidth: 100 },
	{ id: 'is_display_aog', label: 'Is Display AOG', minWidth: 100 },
	{ id: 'is_display_mog', label: 'Is Display MOG', minWidth: 100 },
	{ id: 'action', label: 'Action', minWidth: 140 },

];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		// maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const ListProduct = (props) => {
	const {
		onFetchProduct,
		onDeleteProduct,
		products,
		loading,
		counting,
		name
	} = props;

	console.log(name)

	const classes = useStyles();

	const [dialogOpen, setDialogOpen] = React.useState({
		open: false,
		item: null
	});

	const handleClickDialogOpen = (product) => {
		setDialogOpen({
			open: true,
			item: product
		});
	};

	const handleCloseDialog = () => {
		setDialogOpen({
			open: false,
			item: null
		});
	};

	const handleProductDelete = (product) => {
		onDeleteProduct(product.id, history)
		handleCloseDialog()
	}

	useEffect(() => {
		// onFetchProduct('','')
		const timer = setTimeout(() => {
			onFetchProduct('', name)
		}, 1000)

		return () => clearTimeout(timer)
	}, [onFetchProduct, counting, name]);

	const history = useHistory()
	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(10)
	var no = 1

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	let tableProduct = '';
	if (!isEmpty(products)) {
		tableProduct = (
			<Fragment>
				<TableBody>
					{products.map((product) => (
						<TableRow key={product.id}>
							<TableCell>
								{no++}
							</TableCell>
							<TableCell>
								<CapitalizedText text={product.name} />
							</TableCell>
							<TableCell>
								{product.weight}
							</TableCell>
							<TableCell>
								<CapitalizedText text={product.unit} />
							</TableCell>
							<TableCell>
								{product.is_active === '1' ? "Aktif" : "Non Aktif"}
							</TableCell>
							<TableCell>
								{product.is_display_egs === '1' ? "Aktif" : "Non Aktif"}
							</TableCell>
							<TableCell>
								{product.is_display_aog === '1' ? "Aktif" : "Non Aktif"}
							</TableCell>
							<TableCell>
								{product.is_display_mog === '1' ? "Aktif" : "Non Aktif"}
							</TableCell>
							<TableCell>
								<Tooltip title="Edit Produk">
									<RouterLink to={`/product/edit/${product.id}`}>
										<IconButton aria-label="edit">
											<EditIcon />
										</IconButton>
									</RouterLink>
								</Tooltip>
								<Tooltip title="Hapus Produk">
									<IconButton aria-label="delete" onClick={() => handleClickDialogOpen(product)}>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				</TableBody>
				<TableFooter>
					<TablePagination
						rowsPerPageOptions={[10]}
						colSpan={8}
						count={products.length}
						rowsPerPage={10}
						page={page}
						onChangePage={handleChangePage}
						ActionsComponent={TablePaginationsArrayActions}
					/>
				</TableFooter>
			</Fragment>
		)
	}

	return (loading ? <Loading /> :
		<Fragment>
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						{tableProduct}
					</Table>
				</TableContainer>
			</Paper>
			<Dialog
				open={dialogOpen.open}
				keepMounted
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{"Konfirmasi Penghapusan ProductData"}</DialogTitle>
				<DialogContent>
					{dialogOpen.item !== null && (
						<DialogContentText id="alert-dialog-slide-description">
							Apakah anda yakin ingin menghapus produk {dialogOpen.item.name} {dialogOpen.item.weight} {dialogOpen.item.unit}?
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Batal
					</Button>
					<Button onClick={() => handleProductDelete(dialogOpen.item)} color="primary">
						Hapus
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}

ListProduct.propTypes = {
	getProduct: PropTypes.func.isRequired
}

const mapStateToProps = state => {
	return {
		products: state.product.productsV2,
		loading: state.product.loadingV2,
		counting: state.product.counting
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onFetchProduct: (product_type, name) => dispatch(actions.fetchProducts(product_type, name)),
		onDeleteProduct: (id, history) => dispatch(actions.delProduct(id, history))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProduct)