import React, { Fragment } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit } from "@material-ui/icons";

const RoundData = props => {
  const { round, index, edited } = props;

  return (
    <Fragment>
      <TableRow key={round.id}>
        <TableCell>
          {index + 1}
        </TableCell>
        <TableCell>
          {round.str_name}
        </TableCell>
        <TableCell>
          {round.is_operational === 1 ? 'Ya' : 'Tidak'}
        </TableCell>
        <TableCell>
          {round.open_time_start}
        </TableCell>
        <TableCell>
          {round.open_time_end}
        </TableCell>
        <TableCell>
          {round.admin_name}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Ronde">
            <IconButton aria-label="edited" onClick={() => edited(round)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RoundData;