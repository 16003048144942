import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  MenuItem,
  CardActions,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import SchemaValidation from './validation';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { addProduct } from '../../../actions/product';

// component
import UploadImage from './UploadImage';
import { Loading } from '../../../components/UI';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  btn: {
    backgroundColor: '#011747',
    color: '#FFF',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CreateProduct = ({ addProduct, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SchemaValidation),
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      is_display_egs: false,
      is_display_aog: false,
      is_display_mog: false,
      is_active: false
    },
    touched: {},
    errors: {},
  });

  // Handle Change Banner
  const [banner, setBanner] = useState([]);
  const [base64, setB64] = useState();
  const handleChangeBanner = (event) => {
    setBanner(event[0]);
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = function () {
      setB64(reader.result);
      // console.log(reader.result);
    };
  };

  const handleChange = (event) => {
    // console.log(event.target.value);
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeIsDisplay = (name, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
    }));
  }

  const onSubmit = (e) => {
    // console.log(formState.values, base64)
    addProduct(formState.values, base64, history);
  };

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant='h4'>Buat Produk Baru</Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue={formState.values.name || ''}
                          label='Nama Produk'
                          margin='dense'
                          name='name'
                          onChange={handleChange}
                          helperText={errors.name && errors.name.message}
                          error={errors.name && true}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue={formState.values.weight || ''}
                          label='Berat atau Banyak'
                          margin='dense'
                          name='weight'
                          onChange={handleChange}
                          helperText={errors.weight && errors.weight.message}
                          error={errors.weight && true}
                          inputRef={register}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue={formState.values.unit || ''}
                          label='Unit'
                          margin='dense'
                          name='unit'
                          onChange={handleChange}
                          helperText={errors.unit && errors.unit.message}
                          error={errors.unit && true}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue={formState.values.type || ''}
                          label='Tipe Produk'
                          margin='dense'
                          name='type'
                          onChange={handleChange}
                          helperText={errors.type && errors.type.message}
                          error={errors.type && true}
                          inputRef={register}
                          select
                        >
                          <MenuItem key='gold' value='gold'>
                            Emas
                          </MenuItem>
                          <MenuItem key='dinar' value='dinar'>
                            Dinar
                          </MenuItem>
                          <MenuItem key='dirham' value='dirham'>
                            Dirham
                          </MenuItem>
                          <MenuItem key='other' value='other'>
                            Other
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue={formState.values.is_active || false}
                          label='Status'
                          margin='dense'
                          name='is_active'
                          onChange={handleChange}
                          helperText={errors.is_active && errors.is_active.message}
                          error={errors.is_active && true}
                          inputRef={register}
                          select
                        >
                          <MenuItem key='active' value={true}>
                            Aktif
                          </MenuItem>
                          <MenuItem key='inactive' value={false}>
                            Tidak Aktif
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name='egs'
                                    value={formState.values.is_display_egs}
                                    onChange={(e) => handleChangeIsDisplay('is_display_egs', e.target.checked)}
                                  />
                                }
                                label='Tampil di EGS'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name='aog'
                                    value={formState.values.is_display_aog}
                                    onChange={(e) => handleChangeIsDisplay('is_display_aog', e.target.checked)}
                                  />
                                }
                                label='Tampil di AOG'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name='mog'
                                    value={formState.values.is_display_mog}
                                    onChange={(e) => handleChangeIsDisplay('is_display_mog', e.target.checked)}
                                  />
                                }
                                label='Tampil di MOG'
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      type='submit'
                      size='medium'
                      variant='contained'
                      className={classes.btn}
                    >
                      Simpan
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Card>
                  <CardContent>
                    <UploadImage
                      value={banner}
                      handleChangeBanner={handleChangeBanner}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.product.loadingV2,
  };
};

export default connect(mapStateToProps, { addProduct })(CreateProduct);
