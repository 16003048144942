import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';

import { TimePicker } from '@material-ui/pickers';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Loading } from '../../../components/UI';
import moment from 'moment';
import { setAlert } from '../../../actions/alert';

const schema = yup.object().shape({});

const CreateRound = (props) => {
  const {
    classes,
    closedModalDialog,
    errorForm,
    loading,
    onUpdateOperational,
    clearErrorOperational,
    round,
    setAlert,
  } = props;

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrorOperational();
  }, [clearErrorOperational]);

  const onSubmit = (data) => {
    onUpdateOperational({
      ...formState,
      open_time_start: moment(openedTime).format('HH:mm:ss'),
      open_time_end: moment(closedTime).format('HH:mm:ss'),
      is_operational: formState.is_operational ? '1' : '0',
    });

    closedModalDialog();
  };

  const [formState, setFormState] = useState({
    system_name: round.system_name,
    open_time_start: round.open_time_start,
    open_time_end: round.open_time_end,
    is_operational: round.is_operational === 1,
  });

  const handleChange = (event) => {
    const target = event.target.name;
    event.persist();

    setFormState((formState) => ({
      ...formState,
      [target]: event.target.value || event.target.checked,
    }));
  };

  const handleClosedTime = (endTime) => {
    if (endTime < openedTime) {
      setAlert('Jam tutup tidak bisa kurang dari jam buka', 'error');
    } else {
      setClosedTime(endTime);
    }
  };

  const [openedTime, handleOpenedTime] = useState(
    moment(`2000-01-01 ${round.open_time_start}`)
  );
  const [closedTime, setClosedTime] = useState(
    moment(`2000-01-01 ${round.open_time_end}`)
  );

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={3} className={classes.paperRoot}>
          <Box display='flexDirection'>
            <Box className={classes.boxMarginRow}>
              <TextField
                select
                label='Nama Hari'
                variant='outlined'
                fullWidth
                size={'small'}
                name={'name'}
                inputRef={register}
                error={errorForm.system_name ? true : false}
                helperText={errorForm.system_name && errorForm.system_name[0]}
                defaultValue={formState.system_name}
                onChange={handleChange}
              >
                <MenuItem value='sunday'>Minggu</MenuItem>
                <MenuItem value='monday'>Senin</MenuItem>
                <MenuItem value='tuesday'>Selasa</MenuItem>
                <MenuItem value='wednesday'>Rabu</MenuItem>
                <MenuItem value='thursday'>Kamis</MenuItem>
                <MenuItem value='friday'>Jum'at</MenuItem>
                <MenuItem value='saturday'>Sabtu</MenuItem>
              </TextField>
            </Box>
            <Box className={classes.boxMarginRow}>
              <TimePicker
                clearable
                fullWidth
                inputVariant='outlined'
                ampm={false}
                label='Jam Buka (WIB)'
                name='open'
                error={errorForm.open ? true : false}
                helperText={errorForm.open && errorForm.open[0]}
                inputRef={register}
                value={openedTime}
                onChange={handleOpenedTime}
              />
            </Box>
            <Box className={classes.boxMarginRow}>
              <TimePicker
                clearable
                fullWidth
                inputVariant='outlined'
                ampm={false}
                label='Jam Tutup (WIB)'
                name='close'
                error={errorForm.close ? true : false}
                helperText={errorForm.close && errorForm.close[0]}
                inputRef={register}
                value={closedTime}
                onChange={handleClosedTime}
              />
            </Box>
            <Box className={classes.boxMarginRow}>
              <Box style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography>Hari Operational : </Typography>
                <Typography>Tidak</Typography>
                <Switch
                  checked={formState.is_operational}
                  onChange={handleChange}
                  color='primary'
                  name='is_operational'
                />
                <Typography>Ya</Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Box>
              <Button
                variant={'outlined'}
                size={'small'}
                className={classes.cancelButton}
                onClick={closedModalDialog}
              >
                Batal
              </Button>
            </Box>
            <Box>
              <Button
                type={'submit'}
                variant={'contained'}
                size={'small'}
                className={classes.submitButton}
              >
                Simpan
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.operational.loading,
    errorForm: state.operational.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateOperational: (id, storeData) =>
      dispatch(actions.updateOperational(id, storeData)),
    clearErrorOperational: () => dispatch(actions.clearErrorOperational()),
    setAlert: (msg, type) => dispatch(setAlert(msg, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRound);
