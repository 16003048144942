import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchOpetaionalsStart = () => {
  return {
    type: actionTypes.FETCH_OPERATIONALS_START
  }
}

export const fetchOpetaionalsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_OPERATIONALS_SUCCESS,
    data: data
  }
}

export const fetchOpetaionalsFail = (error) => {
  return {
    type: actionTypes.FETCH_OPERATIONALS_FAIL,
    error: error
  }
}

export const fetchOpetaionals = () => {
  return dispatch => {
    dispatch(fetchOpetaionalsStart())
    axios.get(`admin/aogmog/time-limit`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchOpetaionalsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchOpetaionalsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateOperationalStart = () => {
  return {
    type: actionTypes.UPDATE_OPERATIONAL_START
  }
}

export const updateOperationalSuccess = () => {
  return {
    type: actionTypes.UPDATE_OPERATIONAL_SUCCESS,
  }
}

export const updateOperationalFail = (error) => {
  return {
    type: actionTypes.UPDATE_OPERATIONAL_FAIL,
    error: error
  }
}

export const updateOperational = (storeData) => {
  return dispatch => {
    dispatch(updateOperationalStart())
    axios.put(`admin/aogmog/time-limit`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(updateOperationalSuccess())
        dispatch(setAlert(res.data.message, "success"))

        dispatch(fetchOpetaionals())
      })
      .catch(err => {
        dispatch(updateOperationalFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const clearErrorOperational = () => {
  return {
    type: actionTypes.CLEAR_ERROR_OPERATIONAL
  }
}